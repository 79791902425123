import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import useDeepCompareEffect from "use-deep-compare-effect";
import Funcs from "components/functions";
export const SocketContext = React.createContext();
export const SocketConsumer = SocketContext.Consumer;
let socket;
const useSocketConnectionListener = (
  state,
  setState,
  handleSocketClearLabel,
  handleSocketSetConected
) => {
  useDeepCompareEffect(() => {
    if (socket) {
      socket.onopen = (event) => {
        handleSocketSetConected(true);
      };
      socket.onmessage = (event) => {
        const newLabelFeed = JSON.parse(event.data);
        let labels = state.labels.filter(
          (label) => label.label_status !== "Created"
        );
        const isLabelExist = labels.find(
          (label) => label.label_unique_id === newLabelFeed.label_unique_id
        );
        if (isLabelExist) {
          labels = labels.map((label) => {
            if (label.label_unique_id === newLabelFeed.label_unique_id) {
              return newLabelFeed;
            }
            return label;
          });
        } else {
          labels.push(newLabelFeed);
        }
        setState({ ...state, labels });
      };
      socket.onclose = (event) => {
        handleSocketSetConected(false);
        handleSocketClearLabel();
      };
    }
  }, [state.labels, setState, handleSocketClearLabel, handleSocketSetConected]);
};
const useProcessPaymentsListener = (state, handlePayment) => {
  useDeepCompareEffect(() => {
    state.labels
      .filter(({ label_status }) => label_status === "Created")
      .forEach((label) => {
        handlePayment(label);
      });
  }, [state.labels, state.paid_list, handlePayment]);
};
const usePageChangeListener = (state, handleSocketClearLabel) => {
  const location = useLocation();
  useEffect(() => {
    const completedLabels = state.labels.filter(
      ({ label_status }) => label_status === "Created"
    );
    const errorLabels = state.labels.filter(
      ({ label_status }) => label_status === "Failed"
    );
    const totalLabels = completedLabels.length + errorLabels.length;
    const labelProcessPercent = Math.round(
      (totalLabels / state.labels.length) * 100
    );
    if (labelProcessPercent === 100) {
      handleSocketClearLabel();
    }
    // eslint-disable-next-line
  }, [location.pathname]);
};
const useTrackingListener = (labels, handleTrackingData) => {
  useDeepCompareEffect(() => {
    labels
      .filter(({ label_status }) => label_status === "Created")
      .forEach((label) => {
        handleTrackingData(label.response_data[1].labels);
      });
  }, [labels]);
};
export default function Socket({ updateBalance, children }) {
  const [state, setState] = useState({
    connected: false,
    labels: [],
    packages: [],
    tracking_data: [],
  });
  const token = localStorage.getItem("session_token");
  if (
    token &&
    socket?.readyState !== WebSocket.OPEN &&
    socket?.readyState !== WebSocket.CONNECTING
  ) {
    var ENDPOINT = process.env.REACT_APP_SOCKET_URL + token;
    // replace http with ws
    ENDPOINT = ENDPOINT.replace("http", "ws");
    socket = new WebSocket(ENDPOINT);
  }
  const handleSocketSetConected = (status) => {
    setState({ ...state, connected: status });
  };
  const handleSocketClearLabel = () => {
    setState((prevState) => ({
      ...prevState,
      labels: [],
      packages: [],
      tracking_data: [],
    }));
  };
  const handleSocketSetPackages = (packages) => {
    setState({ ...state, packages: [...state.packages, ...packages] });
  };
  const handleDeductBalance = useCallback(
    (inCentsAmount, currency, tracking_number) => {
      return new Promise((resolve, reject) => {
        const token = "Token " + localStorage.getItem("session_token");
        axios({
          url: localStorage.getItem('apiRootUrl') + "payments/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          data: JSON.stringify({
            amount_money: {
              amount: parseInt(inCentsAmount),
              currency: currency,
            },
            detail: "Payment for shipping",
            tracking_number: tracking_number,
          }),
        })
          .then((response) => {
            if (
              response.data &&
              response.data.detail === "Payment for shipping"
            ) {
              updateBalance();
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            if (error.response) {
              Funcs.check_error(error.response.status);
            }
            reject(error);
          });
      });
    },
    [updateBalance]
  );
  const handleTrackingData = (trackingInfo) => {
    setState((prevState) => ({
      ...prevState,
      tracking_data: [...prevState.tracking_data, trackingInfo],
    }));
  };
  const handlePayment = useCallback(
    async (label) => {
      if (label.response_data && state.packages.length > 0) {
        for (const [index, value] of label.response_data[1].labels.entries()) {
          const label = value;
          const packageData = state.packages.find(
            ({ label_unique_id, name }) =>
              name === `package${index + 1}` &&
              label_unique_id === label.label_unique_id
          );
          if (label.message === "Success") {
            if (packageData.paymentMethod === "using_balance") {
              const rate = parseFloat(packageData.rate);
              const serviceOptionsRate = parseFloat(
                packageData.serviceOptions.includes("+04")
                  ? (packageData.serviceOptions.length - 1) * 1.01
                  : packageData.serviceOptions.length * 1.01
              );
              const discount = parseFloat(packageData.userDiscount);
              const insurance =
                packageData.insurance !== ""
                  ? parseFloat(packageData.insurance)
                  : 0.0;
              const total = parseFloat(rate + serviceOptionsRate + insurance);
              const totalDeduction =
                parseFloat(total - total * (discount / 100)) * 100;
              // console.log(totalDeduction);
              await handleDeductBalance(
                totalDeduction,
                "USD",
                label.trackinginfo_number
              );
            }
          }
        }
      }
    },
    [state.packages, handleDeductBalance]
  );
  useSocketConnectionListener(
    state,
    setState,
    handleSocketClearLabel,
    handleSocketSetConected
  );
  useProcessPaymentsListener(state, handlePayment);
  useTrackingListener(state.labels, handleTrackingData);
  usePageChangeListener(state, handleSocketClearLabel);
  return (
    <SocketContext.Provider
      value={{ state, handleSocketClearLabel, handleSocketSetPackages }}
    >
      {children}
    </SocketContext.Provider>
  );
}
