import { useEffect, useState, useRef } from "react"

export default function Counter({ from = 0, to = 100, start = false }) {
  const [count, setCount] = useState(from)
  const timerRef = useRef(null)
  let intervalRef = useRef(null)
  useEffect(() => {
    intervalRef.current = (5 / 100) * to
    setCount(0)
  }, [to])
  useEffect(() => {
    if (start) {
      if (Math.round(count) < to) {
        timerRef.current = setTimeout(
          () => setCount((prev) => prev + intervalRef.current),
          100
        )
      }
    }
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [count, to, start])

  return Math.round(count)
}
