import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Auth from "components/auth"
import "static/css/popUps.css"

class PopUps extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extendSessionTimeInterval: "",
      extendSessionMinuteLeft: 0,
      extendSessionSecondsLeft: 0,

      // For checking session validity
      sessionChecker: "",
      sessionExpiring: false,
    }
    this.tick = this.tick.bind(this)
  }

  componentDidMount() {
    this.setState({
      sessionChecker: setInterval(() => {
        if (localStorage.getItem("session_token")) {
          const currentDate = Math.abs(new Date())
          const sessionTime = Math.abs(
            new Date(localStorage.getItem("session_duration"))
          )

          if (
            currentDate + 5 * 60 * 1000 >= sessionTime &&
            currentDate < sessionTime
          ) {
            if (!this.state.sessionExpiring) {
              const minutes = Math.floor(
                (sessionTime - currentDate) / 1000 / 60
              )
              const seconds = parseInt(
                (sessionTime - currentDate - minutes * 1000 * 60) / 1000
              )
              this.setState(
                {
                  extendSessionMinuteLeft: minutes,
                  extendSessionSecondsLeft: seconds,
                  sessionExpiring: true,
                },
                () => {
                  document.getElementById("extend-session").style.display =
                    "block"
                }
              )
            }
          } else if (currentDate > sessionTime) {
            Auth.logout(() => {
              window.location.pathname = "/signin"
            })
          }
        }
      }, 1000),
    })
  }

  componentDidUpdate() {
    if (
      this.state.sessionExpiring &&
      this.state.extendSessionTimeInterval === ""
    ) {
      this.setState({
        extendSessionTimeInterval: setInterval(() => {
          this.tick()
        }, 1000),
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.sessionChecker)
  }

  tick() {
    if (
      this.state.extendSessionMinuteLeft ||
      this.state.extendSessionSecondsLeft
    ) {
      this.setState({
        extendSessionMinuteLeft: this.state.extendSessionSecondsLeft
          ? this.state.extendSessionMinuteLeft
          : this.state.extendSessionMinuteLeft - 1,
        extendSessionSecondsLeft: this.state.extendSessionSecondsLeft
          ? this.state.extendSessionSecondsLeft - 1
          : this.state.extendSessionMinuteLeft
          ? 59
          : 0,
      })
    } else {
      clearInterval(this.state.extendSessionTimeInterval)
      this.setState({
        sessionExpiring: false,
        extendSessionTimeInterval: "",
        extendSessionMinuteLeft: 0,
        extendSessionSecondsLeft: 0,
      })
      document.getElementById("extend-session").style.display = "none"
      Auth.logout(() => {
        window.location.pathname = "/signin"
      })
    }
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        {/* This is the loader popup */}
        <div id="spinner" className="spinner-cont">
          <div className="spinner">{/* <i className="fa fa-check"></i> */}</div>
          <div className="spinner-box">
            <div id="spinner-title" className="spinner-title"></div>
          </div>
        </div>
        {/* This is the success popup */}
        <div id="success" className="success-cont">
          <div className="success">
            <FontAwesomeIcon icon="check" />
          </div>
          <div className="success-box">
            <div id="success-title" className="success-title">
              SUCCESS
            </div>
          </div>
        </div>
        {/* This is the confirm dialog */}
        <div id="confirm" className="confirm-cont">
          <div className="confirm-box">
            <div id="confirm-title" className="confirm-title"></div>
            <div className="row confirm-btns">
              <button
                type="button"
                onClick={() => {
                  this.props.appStateSetter({ confirmResult: true })
                }}
              >
                YES
              </button>
              <button
                type="button"
                onClick={() => {
                  document.getElementById("confirm").style.display = "none"
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
        {/* This is the session expire dialog */}
        <div id="alert-dialog" className="alert-dialog-cont">
          <div className="alert-dialog-box">
            <div id="alert-dialog-title" className="alert-dialog-title">
              Session timed out or invalid! Please sign in again to restart
              session.
            </div>
            <div className="row alert-dialog-btns">
              <button
                type="button"
                onClick={() => {
                  Auth.logout(() => {
                    window.location.pathname = "/signin"
                    document.getElementById("alert-dialog").style.display =
                      "none"
                  })
                }}
              >
                SIGN IN
              </button>
            </div>
          </div>
        </div>
        {/* This is the session extend dialog */}
        <div id="extend-session" className="extend-session-cont">
          <div className="extend-session-box">
            <div id="extend-session-title" className="extend-session-title">
              Session will expire or end in{" "}
              {`${this.state.extendSessionMinuteLeft}:${
                this.state.extendSessionSecondsLeft < 10
                  ? "0" + this.state.extendSessionSecondsLeft
                  : this.state.extendSessionSecondsLeft
              }`}
              . Do you want to continue?
            </div>
            <div className="row extend-session-btns">
              <button
                type="button"
                onClick={() => {
                  document.getElementById("extend-session").style.display =
                    "none"
                  const sessionTime = new Date(
                    localStorage.getItem("session_duration")
                  )
                  sessionTime.setDate(sessionTime.getDate() + 1)
                  localStorage.setItem("session_duration", sessionTime)
                  clearInterval(this.state.extendSessionTimeInterval)
                  this.setState({
                    sessionExpiring: false,
                    extendSessionTimeInterval: "",
                    extendSessionMinuteLeft: 0,
                    extendSessionSecondsLeft: 0,
                  })
                }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
        {/* This is the verify email dialog */}
        <div id="verify-email" className="verify-email-cont">
          <div className="verify-email-box">
            <div id="verify-email-title" className="verify-email-title"></div>
            <div className="row verify-email-btns">
              <button
                type="button"
                onClick={() => {
                  document.getElementById("verify-email").style.display = "none"
                  this.props.appStateSetter({ signupConfirmResult: true })
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        {/* This is the forgot password dialog */}
        <div id="forgot-password" className="verify-email-cont">
          <div className="verify-email-box">
            <div
              id="forgot-password-title"
              className="verify-email-title"
            ></div>
            <div className="row verify-email-btns">
              <button
                type="button"
                onClick={() => {
                  document.getElementById("verify-email").style.display = "none"
                  window.location.pathname = "/signin"
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopUps
