import React, { Suspense } from "react"
import { Route, Redirect } from "react-router-dom"
import Auth from "components/auth"

const ProtectedLoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!Auth.isAuthenticated()) {
          return (
            <Suspense fallback={<></>}>
              <Component
                appRootUrl={rest.appRootUrl}
                // For updating user info
                updateUserInfo={() => rest.updateUserInfo()}
                // For signup confirm dialog
                signupConfirmResult={rest.signupConfirmResult}
                appStateSetter={(state) => rest.appStateSetter(state)}
                exact
                {...props}
              />
            </Suspense>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname:
                  props.location.pathname === "/"
                    ? "/ship"
                    : props.history.goBack(),
              }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedLoginRoute
