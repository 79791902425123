export const defaultAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1 },
}
export const modalAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
}

export const pageAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: "easeOut" },
}
export const tableHeaderAnimation = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
  transition: { duration: 1 },
}

export const spinnerImageAnimation = {
  initial: { opacity: 0.5, y: -2 },
  animate: { opacity: 1, y: 0 },
  transition: {
    repeat: Infinity,
    repeatType: "reverse",
    duration: 1,
  },
}

export const creditCardAnimation = {
  initial: { y: -10 },
  animate: { y: 0 },
  transition: { duration: 1 },
}
