import React from "react"
import ReactDom from "react-dom"
import { motion, AnimatePresence } from "framer-motion"
import { defaultAnimation, spinnerImageAnimation } from "animations"
import sideBarLogo from "static/image/sidebar-logo.png"
import "static/css/spinner.css"
export default function Spinner({ show, title, height, global = true }) {
  if (global) {
    return ReactDom.createPortal(
      <AnimatePresence>
        {show && (
          <motion.div className="spinner-container" {...defaultAnimation}>
            <div className="spinner-body">
              <motion.img
                src={sideBarLogo}
                alt="Side Bar Logo"
                {...spinnerImageAnimation}
              />
              <p>{title}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.getElementById("custom_spinner")
    )
  }
  return (
    <div className="non-global-spinner-container" style={{ height }}>
      <motion.img
        src={sideBarLogo}
        alt="Side Bar Logo"
        {...spinnerImageAnimation}
      />
    </div>
  )
}
