import axios from "axios"

class Funcs {
  check_error(status_number) {
    if (status_number === 401) {
      document.getElementById("spinner").style.display = "none"
      document.getElementById("alert-dialog").style.display = "block"
    } else if (status_number === 404) {
      document.getElementById("spinner").style.display = "none"
    } else if (status_number === 500) {
      document.getElementById("spinner").style.display = "none"
    }
  }

  get_user_balance() {
    if (localStorage.getItem("session_token") !== null) {
      const balance = axios({
        url: localStorage.getItem("apiRootUrl") + "payments/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Token " + localStorage.getItem("session_token"),
        },
      })
        .then((response) => {
          if (response.data && response.data.balance) {
            return response.data.balance
          } else {
          }
        })
        .catch((error) => {
          if (error.response) {
            this.check_error(error.response.status)
            if (error.response.data.detail === "Not found.") {
              return "0.00"
            }
          }
        })
      return balance
    }
  }

  get_user_information() {
    if (localStorage.getItem("session_token") !== null) {
      const userInfo = axios
        .all([
          axios.get(`${localStorage.getItem("apiRootUrl")}rest-auth/user/`, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Token " + localStorage.getItem("session_token"),
            },
          }),
          axios.get(`${localStorage.getItem("apiRootUrl")}get/user/discount/`, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Token " + localStorage.getItem("session_token"),
            },
          }),
          axios.get(
            `${localStorage.getItem("apiRootUrl")}get/user/group/info/`,
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Token " + localStorage.getItem("session_token"),
              },
            }
          ),
        ])
        .then(
          axios.spread((userDetails, userDiscount, groupRates) => {
            return {
              ...userDetails.data,
              ...groupRates.data,
              userDiscount: parseFloat(
                userDiscount.data !== "" ? userDiscount.data : 0.0
              ),
            }
          })
        )
      return userInfo
    }
  }

  stringToTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }

  randomStringGenerator(length) {
    let result = ""
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

export default new Funcs()
