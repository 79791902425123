class Auth {
  login(cb, token, user, username, remember) {
    const sessionTime = new Date()
    if (remember) {
      sessionTime.setFullYear(sessionTime.getFullYear() + 1)
    } else {
      sessionTime.setDate(sessionTime.getDate() + 1)
    }
    localStorage.removeItem("uwl_admin_session_token")
    localStorage.setItem("session_token", token)
    localStorage.setItem("session_duration", sessionTime)
    localStorage.setItem("user", user)
    cb()
  }

  logout(cb) {
    localStorage.removeItem("session_token")
    localStorage.removeItem("session_duration")
    localStorage.removeItem("user")
    cb()
  }

  isAuthenticated() {
    const isAuth = localStorage.getItem("session_token")
    return isAuth
  }
}

export default new Auth()
