import React from "react"
import ReactDOM from "react-dom"
import "index.css"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from "App"
import * as serviceWorker from "serviceWorker"
//LocalStorage Fallback
if (!("localStorage" in window)) {
  import("local-storage-fallback").then((storage) => {
    window.localStorage = storage
  })
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.8,
  })
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
