import React, { useState, useContext, useEffect, Suspense } from "react"
import { Route, Redirect } from "react-router-dom"
import Auth from "components/auth"
import { SocketContext } from "components/partial/Socket"
import Notification from "components/partial/Notification"
import Spinner from "components/partial/Spinner"

const SocketNotification = () => {
  const [localState, setLocalState] = useState({
    notification: {
      show: true,
      message: "",
      type: "progress",
      displayContact: false,
    },
  })
  const { state } = useContext(SocketContext)
  const completedLabels = state.labels.filter(
    ({ label_status }) => label_status === "Created"
  )
  const errorLabels = state.labels.filter(
    ({ label_status }) => label_status === "Failed"
  )
  const totalLabels = completedLabels.length - errorLabels.length
  let labelProcessPercent = Math.round(
    (totalLabels > 0
      ? totalLabels
      : state.labels.length / state.labels.length) * 100
  )
  const handleCloseNotification = () => {
    setLocalState({
      notification: {
        show: false,
        message: "",
        type: "",
        displayContact: false,
      },
    })
  }
  useEffect(() => {
    setLocalState({
      notification: {
        show: true,
        message: "",
        type: "progress",
        displayContact: false,
      },
    })
  }, [state.labels])
  return (
    <>
      {state.labels.length > 0 && (
        <Notification
          {...localState.notification}
          percent={labelProcessPercent}
          report={{
            completedCount: completedLabels.length,
            errorCount: errorLabels.length,
          }}
          message={
            labelProcessPercent === 100 || errorLabels.length > 0
              ? `Generating labels`
              : `Processing label(s)`
          }
          drag={true}
          onClose={() => {
            handleCloseNotification()
          }}
        />
      )}
    </>
  )
}
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isAuthenticated()) {
          return (
            <>
              <SocketNotification />
              <Suspense fallback={<Spinner global={false} height="80vh" />}>
                <Component
                  appRootUrl={rest.appRootUrl}
                  // For user information
                  updateUserInfo={() => rest.updateUserInfo()}
                  userInfo={rest.userInfo}
                  // For updating sidebar balance
                  updateBalance={() => rest.updateBalance()}
                  // For confirm dialog
                  confirmResult={rest.confirmResult}
                  appStateSetter={(state) => rest.appStateSetter(state)}
                  exact
                  {...props}
                />
              </Suspense>
            </>
          )
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedRoute
