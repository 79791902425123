import React, { useState } from "react"
import ReactDOM from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, AnimatePresence } from "framer-motion"
import Counter from "components/partial/Counter"
import useDeepCompareEffect from "use-deep-compare-effect"
import "static/css/partialsCss/notification.css"
const defaultNotificationState = {
  show: "",
  message: "",
  onPercentAnimationCompleted: false,
  startCounter: false,
}
const useTimer = (duration, type, cb) => {
  useDeepCompareEffect(() => {
    if (duration !== 0 && type !== "progress" && type !== undefined) {
      const timeOut = setTimeout(cb, duration)
      return () => {
        clearTimeout(timeOut)
      }
    }
  }, [duration, type, cb])
}
const Progress = ({ message, percent, setProcessAnimationStatus }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div className="percent">
        <svg>
          <circle cx="70" cy="70" r="70"></circle>
          <motion.circle
            className="progress"
            cx="70"
            cy="70"
            r="70"
            initial={{ strokeDashoffset: 440 }}
            animate={{ strokeDashoffset: 440 - (440 * percent) / 100 }}
            transition={{ duration: 5 }}
            onAnimationComplete={() => setProcessAnimationStatus()}
          ></motion.circle>
        </svg>
        <div className="number">
          <h2>
            {percent}
            <span>%</span>
          </h2>
        </div>
      </div>
      <p>{message}</p>
    </div>
  )
}

export default function Notification({
  show,
  type = "",
  onClose,
  duration = 0,
  message,
  drag = false,
  percent = 0,
  report = { completedCount: 0, errorCount: 0 },
  displayContact = false,
}) {
  const [notificationProperties, setNotificationProperties] = useState(
    defaultNotificationState
  )
  const hideNotification = () => {
    setNotificationProperties(defaultNotificationState)
    onClose()
  }
  const setProcessAnimationStatus = () => {
    setNotificationProperties((prevState) => ({
      ...prevState,
      onPercentAnimationCompleted: true,
    }))
  }
  const handleStartCounter = () => {
    if (notificationProperties.onPercentAnimationCompleted) {
      setNotificationProperties((prevState) => ({
        ...prevState,
        startCounter: true,
      }))
    }
  }
  useTimer(duration, type, hideNotification)
  useDeepCompareEffect(() => {
    setNotificationProperties((prevState) => ({
      ...prevState,
      show,
      message,
    }))
  }, [show, type, onClose, message])
  return ReactDOM.createPortal(
    <AnimatePresence>
      {notificationProperties.show && (
        <motion.div
          className={`notification ${type}`}
          style={{ cursor: drag ? "grabbing" : "" }}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          drag={drag}
          dragElastic={1}
          dragMomentum={false}
        >
          <div className="content">
            {type === "progress" && (
              <>
                <button
                  className="btn-close"
                  onClick={(e) => {
                    e.stopPropagation()
                    hideNotification()
                  }}
                >
                  X
                </button>
                <motion.div
                  className="card"
                  animate={{
                    transform:
                      percent === 100 &&
                      notificationProperties.onPercentAnimationCompleted
                        ? "rotateY(180deg)"
                        : "rotateY(0deg)",
                  }}
                  onAnimationComplete={() => handleStartCounter()}
                >
                  <Progress
                    clasName="front face"
                    message={notificationProperties.message}
                    percent={percent}
                    setProcessAnimationStatus={setProcessAnimationStatus}
                  />
                  <div className="back face">
                    <div className="data">
                      <p className="count">
                        <Counter
                          to={report.completedCount}
                          start={notificationProperties.startCounter}
                        />
                      </p>
                      <p>Complete</p>
                    </div>
                    <div className="data">
                      <p className="count error">
                        <Counter
                          to={report.errorCount}
                          start={notificationProperties.startCounter}
                        />
                      </p>
                      <p>Error</p>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
            {(type === "error" || type === "info") && (
              <p>
                <motion.i
                  initial={{ y: -10 }}
                  animate={{ y: [-10, -7, -4, 0, -4, -7, -10] }}
                  transition={{
                    ease: "easeOut",
                    repeat: Infinity,
                    type: "spring",
                    stiffness: 1000,
                    duration: 2,
                  }}
                >
                  <FontAwesomeIcon
                    icon={type === "error" ? "exclamation" : "check-circle"}
                    size="2x"
                  />
                </motion.i>
                {message}
                <br />
                {displayContact && (
                  <a href="/help">Please contact Administrator</a>
                )}
              </p>
            )}
            {/* {type === "socket" && (
              <>
                <button
                  className="btn-close"
                  onClick={() => {
                    hideNotification()
                  }}
                >
                  X
                </button>
                <Progress
                  message={notificationProperties.message}
                  percent={percent}
                />
              </>
            )} */}
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById("notification")
  )
}
