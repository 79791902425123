import React, { Component, lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faPlusCircle,
  faMinusCircle,
  faCheck,
  faTimes,
  faSpinner,
  faPlus,
  faDownload,
  faFilter,
  faEllipsisV,
  faAngleDown,
  faSave,
  faSync,
  faSortDown,
  faPrint,
  faClock,
  faImages,
  faCircleNotch,
  faChevronUp,
  faChevronDown,
  faComments,
  faBriefcase,
  faMoneyCheckAlt,
  faInfoCircle,
  faQuestionCircle,
  faCreditCard,
  faPaperPlane,
  faMinus,
  faSign,
  faSignOutAlt,
  faTrash,
  faFile,
  faUpload,
  faSearch,
  faShoppingCart,
  faBook,
  faCog,
  faTasks,
  faHistory,
  faPhone,
  faEnvelope,
  faArrowLeft,
  faEyeSlash,
  faEye,
  faUser,
  faExternalLinkAlt,
  faUserCircle,
  faBuilding,
  faKey,
  faCheckCircle,
  faExclamation,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons"
import Analytics from "components/partial/Analytics"
import Funcs from "components/functions"
import PopUps from "components/popUpsContainer"
import SocketContainer from "components/partial/Socket"
import ProtectedRoute from "components/protectRoute"
import ProtectedLoginRoute from "components/protectLoginRoute"
import Spinner from "components/partial/Spinner"
import "App.css"
library.add(
  faMinusCircle,
  faPlusCircle,
  faCheck,
  faTimes,
  faSpinner,
  faPlus,
  faDownload,
  faFilter,
  faEllipsisV,
  faAngleDown,
  faSave,
  faSync,
  faSortDown,
  faPrint,
  faClock,
  faImages,
  faCircleNotch,
  faChevronUp,
  faChevronDown,
  faComments,
  faBriefcase,
  faMoneyCheckAlt,
  faInfoCircle,
  faQuestionCircle,
  faCreditCard,
  faPaperPlane,
  faMinus,
  faSign,
  faSignOutAlt,
  faTrash,
  faFile,
  faUpload,
  faSearch,
  faShoppingCart,
  faBook,
  faClock,
  faCog,
  faTasks,
  faHistory,
  faPhone,
  faEnvelope,
  faArrowLeft,
  faEye,
  faEyeSlash,
  faUser,
  faExternalLinkAlt,
  faUserCircle,
  faBuilding,
  faKey,
  faCheckCircle,
  faExclamation,
  faSignInAlt
)
const Home = lazy(() => import("components/home"))
const SignIn = lazy(() => import("components/signin"))
const SignUp = lazy(() => import("components/signup"))
const ResetPassword = lazy(() => import("components/resetPassword"))
const VerifyEmail = lazy(() => import("components/verifyEmail"))
const ForgotPassword = lazy(() => import("components/forgotPassword"))
const ShipContainer = lazy(() => import("components/shipContainer"))
const BatchContainer = lazy(() => import("components/batchContainer"))
const AddressBookContainer = lazy(() =>
  import("components/addressBookContainer")
)
const HistoryContainer = lazy(() => import("components/historyContainer"))
const SettingsContainer = lazy(() => import("components/settingsContainer"))
const HelpPageContainer = lazy(() => import("components/helpContainer"))
const shipSuccessContainer = lazy(() =>
  import("components/shipSuccessContainer")
)
const Navbar = lazy(() => import("components/navbar"))
const Sidebar = lazy(() => import("components/sidebar"))
const Error404 = lazy(() => import("components/404"))
const AdminContainer = lazy(() => import("admin/src/App"))
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appRootUrl: "https://logisticscenterusa.com/",
      apiRootUrl: "https://logisticscenterusa.com:8000/",
      userBalance: "",
      confirmResult: null,
      signupConfirmResult: null,
      userInfo: {},
    }
    this.updateBalance = this.updateBalance.bind(this)
    this.updateUserInfo = this.updateUserInfo.bind(this)
    this.appStateSetter = this.appStateSetter.bind(this)
  }
  componentDidMount() {
    this.updateBalance()
    this.updateUserInfo()
    localStorage.setItem("appRootUrl", this.state.appRootUrl)
    localStorage.setItem("apiRootUrl", this.state.apiRootUrl)
  }

  componentWillUnmount() {}

  updateBalance() {
    if (localStorage.getItem("session_token") !== null) {
      Funcs.get_user_balance().then((result) => {
        this.setState({ userBalance: result })
      })
    }
  }

  updateUserInfo() {
    if (localStorage.getItem("session_token") !== null) {
      Funcs.get_user_information().then((result) => {
        this.setState({ userInfo: result })
      })
    }
  }

  appStateSetter(state) {
    this.setState(state)
  }

  render() {
    return (
      <Router>
        <PopUps appStateSetter={(state) => this.appStateSetter(state)} />
        {localStorage.getItem("uwl_admin_session_token")?.length > 0 &&
          !window.location.href.includes("/admin") && <Redirect to="/admin" />}
        <Analytics />
        <Switch>
          <ProtectedLoginRoute
            path="/admin"
            appRootUrl={this.state.apiRootUrl}
            updateUserInfo={() => this.updateUserInfo()}
            component={AdminContainer}
          />
          <ProtectedLoginRoute
            path="/signin"
            appRootUrl={this.state.apiRootUrl}
            updateUserInfo={() => this.updateUserInfo()}
            exact
            component={SignIn}
          />
          <ProtectedLoginRoute
            path="/signup"
            appRootUrl={this.state.apiRootUrl}
            signupConfirmResult={this.state.signupConfirmResult}
            appStateSetter={(state) => this.appStateSetter(state)}
            exact
            component={SignUp}
          />
          <ProtectedLoginRoute
            path="/verify_email/:key"
            appRootUrl={this.state.apiRootUrl}
            appStateSetter={(state) => this.appStateSetter(state)}
            exact
            component={VerifyEmail}
          />
          <ProtectedLoginRoute
            path="/forgot-password"
            appRootUrl={this.state.apiRootUrl}
            appStateSetter={(state) => this.appStateSetter(state)}
            exact
            component={ForgotPassword}
          />
          <ProtectedLoginRoute
            path="/password_reset/:uid/:token"
            appRootUrl={this.state.apiRootUrl}
            appStateSetter={(state) => this.appStateSetter(state)}
            exact
            component={ResetPassword}
          />
          <Suspense fallback={<Spinner />}>
            <Route
              path="/"
              appRootUrl={this.state.apiRootUrl}
              exact
              component={Home}
            />
            {window.location.pathname !== "/dashboard" ? (
              <div className="app-container">
                {window.location.pathname !== "/" ? (
                  <Sidebar
                    appRootUrl={this.state.apiRootUrl}
                    balance={this.state.userBalance}
                  />
                ) : null}

                <div
                  className="cont-body"
                  style={{
                    marginLeft:
                      window.location.pathname !== "/" ? "250px" : "0",
                  }}
                >
                  {window.location.pathname !== "/" ? (
                    <Navbar
                      appRootUrl={this.state.apiRootUrl}
                      userInfo={this.state.userInfo}
                    />
                  ) : null}
                  <SocketContainer updateBalance={this.updateBalance}>
                    <ProtectedRoute
                      path="/ship"
                      appRootUrl={this.state.apiRootUrl}
                      updateBalance={() => this.updateBalance()}
                      updateUserInfo={() => this.updateUserInfo()}
                      userInfo={this.state.userInfo}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      exact
                      component={ShipContainer}
                    />
                    <ProtectedRoute
                      path="/ship/label"
                      appRootUrl={this.state.apiRootUrl}
                      exact
                      component={shipSuccessContainer}
                    />
                    <ProtectedRoute
                      path="/batch"
                      appRootUrl={this.state.apiRootUrl}
                      updateBalance={() => this.updateBalance()}
                      userInfo={this.state.userInfo}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      exact
                      component={BatchContainer}
                    />
                    <ProtectedRoute
                      path="/addressbook"
                      appRootUrl={this.state.apiRootUrl}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      exact
                      component={AddressBookContainer}
                    />
                    <ProtectedRoute
                      path="/history"
                      appRootUrl={this.state.apiRootUrl}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      updateBalance={() => this.updateBalance()}
                      exact
                      component={HistoryContainer}
                    />
                    <ProtectedRoute
                      path="/settings"
                      appRootUrl={this.state.apiRootUrl}
                      updateBalance={() => this.updateBalance()}
                      updateUserInfo={() => this.updateUserInfo()}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      component={SettingsContainer}
                    />
                    <ProtectedRoute
                      path="/help"
                      appRootUrl={this.state.apiRootUrl}
                      updateBalance={() => this.updateBalance()}
                      updateUserInfo={() => this.updateUserInfo()}
                      userInfo={this.state.userInfo}
                      confirmResult={this.state.confirmResult}
                      appStateSetter={(state) => this.appStateSetter(state)}
                      exact
                      component={HelpPageContainer}
                    />
                  </SocketContainer>
                </div>
              </div>
            ) : (
              <Route path="*" component={Error404} />
            )}
          </Suspense>
          <Route path="*" component={Error404} />
        </Switch>
      </Router>
    )
  }
}

export default App
