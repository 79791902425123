import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import ReactGA from "react-ga"
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
  gaOptions: {
    cookieFlags: "SameSite=None;Secure",
  },
})
const AnalyticsComponent = () => {
  const location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])
  return null
}
export { ReactGA }
export default AnalyticsComponent
